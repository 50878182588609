import Logo from "./Logo";

export default function Footer() {
    const rsvpLinks = [
        {
            title: "אישורי הגעה לחינה",
            href: "#",
        },
        {
            title: "אישורי הגעה לבר מצווה",
            href: "#",
        },
        {
            title: "אישורי הגעה לבת מצווה",
            href: "#",
        },
        {
            title: "אישורי הגעה לאירועים עסקיים",
            href: "#",
        },
    ];

    const companyLinks = [
        {
            title: "מדיניות פרטיות",
            href: "#",
        },
        {
            title: "תנאי שימוש",
            href: "#",
        },
        {
            title: "צור קשר",
            href: "https://api.whatsapp.com/send?phone=972587501168",
        },
    ];
    return (
        <footer className="flex flex-col gap-8 lg:gap-4 bg-rose-300/60 text-rose-800 py-10 px-layout">
            <div className="flex flex-col lg:flex-row gap-8 lg:gap-4">
                <ul className="flex flex-col lg:flex-row gap-3">
                    {rsvpLinks.map((link) => (
                        <li
                            className="underline cursor-pointer"
                            key={link.title}
                        >
                            {link.title}
                        </li>
                    ))}
                </ul>

                <ul className="flex flex-col lg:flex-row gap-3">
                    {companyLinks.map((link) => (
                        <li className="underline" key={link.title}>
                            <a
                                href={link.href}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {link.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex flex-row gap-4 text-sm text-center font-manrope">
                <p>CinCin RSVP &copy; 2024</p>
                <Logo small dark />
            </div>
        </footer>
    );
}
