import weddingGroup from "../assets/wedding-group-small.jpg";
import weddingPhone from "../assets/wedding-phone-small.jpg";
import weddingWatch from "../assets/wedding-watch-small.jpg";
import { HOW_IT_WORKS_SECTION_ID } from "../constants";

export default function HowItWorks() {
    return (
        <div
            id={HOW_IT_WORKS_SECTION_ID}
            className="pt-10 bg-rose-300/60 text-center"
        >
            <h2 className="text-3xl text-primary-dark font-semibold lg:text-5xl py-5">
                איך זה עובד?
            </h2>
            <div className="flex flex-col lg:flex-row gap-0 pt-10 items-center">
                <Card
                    index={0}
                    title="בוחרים מה לשלוח"
                    description="בחרו אילו פרטים יופיעו בהודעה, צרפו את תמונת ההזמנה שלכם וצרו את ההודעה המושלמת"
                    image={weddingPhone}
                />
                <Card
                    index={1}
                    title="בוחרים מתי לשלוח"
                    description="בחרו מתי נשלח את ההודעות בשבילכם. אנחנו ממליצים על שבועיים לפני האירוע בשעה 13:00, כדי לקבל מענה מקסימלי"
                    image={weddingWatch}
                />
                <Card
                    index={2}
                    title="בוחרים למי לשלוח"
                    description="העלו אנשי קשר דרך הוואטסאפ, דרך קובץ אקסל עם המוזמנים שלכם או הזינו ידנית במערכת שלנו"
                    image={weddingGroup}
                />
            </div>
        </div>
    );
}

const Card = ({ title, description, image, index }) => {
    return (
        <div
            className={`${
                index === 1 ? "bg-rose-300/60" : "bg-white"
            } h-screen w-screen flex flex-col items-center justify-center`}
        >
            <div
                className={`h-[70vh] sm:h-[65vh] w-full overflow-hidden relative`}
            >
                <div
                    style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        clipPath: "inset(0 0 0 0)",
                    }}
                    id={`card-image-${index}`}
                    className={`h-[70vh] sm:h-[65vh] w-full`}
                ></div>
            </div>

            <div
                className={`text-start px-8 flex flex-col gap-2 py-10 h-[30%] sm:h-[35%] justify-center
        ${
            index % 2 === 0
                ? "bg-rose-300/60 text-primary-dark"
                : "bg-white text-primary-dark"
            // : "bg-white text-rose-300"
        }
        `}
            >
                <h3 className="text-2xl font-semibold">{title}</h3>
                <p className="text-xl">{description}</p>
            </div>
        </div>
    );
};
