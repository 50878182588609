import { CTAButton } from "../App";
import handsEmoji from "../assets/emojis/Folded hands.svg";
import crownEmoji from "../assets/emojis/Gaspar.svg";
import heartEmoji from "../assets/emojis/Heart.svg";
import partyEmoji from "../assets/emojis/Party popper.svg";

export default function CTASection({
    title = `אישורי הגעה לא צריכים\nלהיות כאב ראש`,
    // title = `יש לכם הרבה דברים להכין\nתנו לנו לטפל באישורים`,
    userId,
    white,
    id,
    withCTA = true,
}) {
    return (
        <div
            className={`text-center flex flex-col gap-8 lg:gap-16 items-center px-8 lg:px-96 xl:px-[30rem] lg:py-32 ${
                white ? "py-20" : "py-24"
            } w-full justify-center  ${
                white
                    ? "bg-white text-rose-800"
                    : // ? "bg-white text-rose-300"
                      "bg-rose-300/60 text-primary-dark"
            }`}
        >
            <div
                id={`top-emojis-${id}`}
                className="flex justify-between w-full lg:max-w-[500px] h-[3.75rem]"
            >
                <img
                    src={partyEmoji}
                    alt="crown"
                    className={`emoji-${id} w-8 h-8 self-end`}
                />
                <img
                    src={handsEmoji}
                    alt="crown"
                    className={`emoji-${id} w-8 h-8`}
                />
                <img
                    src={crownEmoji}
                    alt="crown"
                    className={`emoji-${id} w-8 h-8`}
                />
                <img
                    src={heartEmoji}
                    alt="crown"
                    className={`emoji-${id} w-8 h-8 self-end`}
                />
            </div>
            <div
                id={`cta-content-${id}`}
                className="flex flex-col gap-8 items-center py-2"
            >
                <h5 className="text-3xl px-4 font-semibold whitespace-pre">
                    {title}
                </h5>
                {withCTA ? (
                    <CTAButton
                        userId={userId}
                        white={!white}
                        ctaId={"cta-section-" + id}
                    />
                ) : null}
            </div>
            <div
                id={`bottom-emojis-${id}`}
                className="flex flex-row-reverse justify-between w-full lg:max-w-[500px] h-[3.75rem]"
            >
                <img
                    src={crownEmoji}
                    alt="crown"
                    className={`emoji-${id} w-8 h-8`}
                />
                <img
                    src={handsEmoji}
                    alt="crown"
                    className={`emoji-${id} w-8 h-8 self-end`}
                />
                <img
                    src={partyEmoji}
                    alt="crown"
                    className={`emoji-${id} w-8 h-8 self-end`}
                />
                <img
                    src={heartEmoji}
                    alt="crown"
                    className={`emoji-${id} w-8 h-8`}
                />
            </div>
        </div>
    );
}
