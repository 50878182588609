import logoDark from "../assets/logo-red.png";
// import logoDark from "../assets/logo-dark.png";
import logoRose from "../assets/logo-rose.png";
import logoWhiteEmpty from "../assets/logo-white-empty.svg";
import logoWhite from "../assets/logo-white.png";

export default function Logo({ small, white, dark, emptyWhite }) {
    return (
        <a
            href="/"
            className={`rounded-full cursor-pointer ${
                small ? "w-5 h-5" : "w-10 h-10"
            } ${white ? "bg-white/70" : ""} flex items-center justify-center`}
        >
            <img
                src={
                    dark
                        ? logoDark
                        : white
                        ? logoWhite
                        : emptyWhite
                        ? logoWhiteEmpty
                        : logoRose
                }
                alt="logo"
            />
        </a>
    );
}
