import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBWmvkzXjH_ISswaKwFksyH0eQNiTCfEF0",
    authDomain: "see-you-there-eb2f2.firebaseapp.com",
    projectId: "see-you-there-eb2f2",
    storageBucket: "see-you-there-eb2f2.appspot.com",
    messagingSenderId: "214216184374",
    appId: "1:214216184374:web:847fdec78b52d82df8fe97",
    measurementId: "G-XHCZVY3GTM",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
