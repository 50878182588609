import React from "react";
import { BgPattern, CTAButton } from "../App";
import demoImage from "../assets/chinchin-chat-demo-new.webp";

export default function Hero({ userId }) {
    return (
        <div className="relative pt-28 px-5 lg:px-36">
            <BgPattern id="bg-pattern" className="absolute -left-60 -top-80" />
            <div className="z-10 relative flex flex-col lg:flex-row gap-5 items-center">
                <div className="z-10 relative flex flex-col gap-5 items-center lg:items-start">
                    <h1
                        id="hero-title"
                        className="text-[40px] lg:text-6xl w-full flex flex-col lg:gap-3 leading-10 font-extrabold text-center lg:text-start text-slate-900 font-playfair"
                    >
                        <span className="w-full">צ'ין צ'ין - </span>
                        <span className="w-full">פשוט לאשר הגעה</span>
                        {/* <span className="w-full">אישורי הגעה בוואטסאפ</span> */}
                        {/* <span className="w-full">במחיר שפוי</span> */}
                    </h1>

                    <p className="text-[22px] lg:text-2xl mt-2 w-full flex flex-col gap-0 text-center lg:text-start">
                        בלי חפירות ואנשי מכירות,
                        <br />
                        <span className="font-semibold mx-1">
                            אישורי הגעה בוואטסאפ ברמה אחרת.
                        </span>
                        לאירוע גדול, במחיר קטן
                    </p>
                    {/* <p
                        id="hero-description"
                        className="text-[22px] lg:text-2xl mt-2 w-full flex flex-col gap-0 text-center lg:text-start"
                    >
                        <div className="description-line w-full h-[30px] relative overflow-hidden">
                            <span className="absolute right-0 w-full">
                                בלי חפירות ואנשי מכירות,
                            </span>
                        </div>
                        <div className="description-line w-full h-[30px] relative overflow-hidden">
                            <span className="absolute right-0 w-full">
                                <span className="font-semibold mx-1">
                                    פשוט אישורי הגעה בוואטסאפ.
                                </span>
                            </span>
                        </div>
                        <div className="description-line w-full h-[30px] relative overflow-hidden">
                            <span className="absolute right-0 w-full">
                                לאירוע גדול, במחיר קטן
                            </span>
                        </div>
                    </p> */}
                    <div
                        id="hero-cta"
                        className="flex flex-col gap-3 items-center py-10"
                    >
                        <CTAButton
                            userId={userId}
                            ctaId="hero"
                            title="תראו לי מה מקבלים"
                            scrollTo="#plans"
                        />
                    </div>
                </div>

                <img
                    id="hero-image"
                    src={demoImage}
                    alt="demo"
                    // className="lg:mx-auto z-10 w-[300px] xs:w-[341px] h-[508px] xs:h-[604px] lg:h-auto lg:w-[27.5%] relative px-3"
                    className="lg:mx-auto z-10 w-11/12 lg:w-[27.5%] relative px-3"
                />
            </div>
        </div>
    );
}
