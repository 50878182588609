// import { logEvent } from "firebase/analytics";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import React, { useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useSearchParams } from "react-router-dom";

import { v4 as uuid } from "uuid";
// import demoImage from "./assets/new-demo-portrait.png";
// import demoImage from "./assets/demo-new-portrait.png";
import Hotjar from "@hotjar/browser";
import CTASection from "./components/CTASection";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import MainFeature from "./components/MainFeature";
import HowItWorks from "./components/MoreInfo";
import Navbar from "./components/Navbar";
import Plans from "./components/Plans";
import SystemUsage from "./components/SystemUsage";
import { db } from "./firebase";
import useUserId from "./hooks/useUserId";
gsap.registerPlugin(ScrollTrigger);

const siteId = 4985398;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

export const logEvent = async (type, userId, params) => {
    try {
        const docRef = doc(db, "events-v3", userId);
        const snapshot = await getDoc(docRef);
        const data = {
            events: arrayUnion({
                type,
                time: new Date(),
                ...params,
            }),
        };
        if (snapshot.exists()) {
            return updateDoc(doc(db, "events-v3", userId), data);
        } else {
            return setDoc(doc(db, "events-v3", userId), {
                ...data,
                lang: navigator.language ?? navigator.userLanguage ?? null,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
        }
    } catch (err) {
        console.error(err);
        return setDoc(doc(db, "errors", uuid()), {
            error: JSON.stringify(err),
        });
    }
};

function App() {
    const [searchParams, setSearchParams] = useSearchParams();
    // const { price } = usePrice();
    const { userId } = useUserId();

    useEffect(() => {
        const fbclid = searchParams.get("fbclid") ?? null;
        logEvent("landing", userId, {
            fbclid,
        });
    }, [searchParams, userId]);

    return (
        <div dir="rtl" className="bg-white font-assistant">
            <Navbar userId={userId} />
            <div dir="rtl" className="">
                <Hero userId={userId} />
                <CTASection
                    userId={userId}
                    title={`כל מה שצריך\nכדי להוריד לכם דאגות`}
                    white
                    withCTA={false}
                />
                <MainFeature />
                <Plans userId={userId} />
                <CTASection
                    userId={userId}
                    title={`לשבור את הכוס\nבלי לשבור את הכיס`}
                />
                <HowItWorks />
                <CTASection userId={userId} white />
                <SystemUsage />
                <CTASection
                    userId={userId}
                    title={`אתם בוחרים\nאנחנו מבצעים`}
                />
            </div>
            <Footer />
        </div>
    );
}

export default App;

export const CTAButton = ({
    white,
    userId,
    title = "נסו עכשיו בחינם",
    ctaId,
    primary = false,
    scrollTo = null,
}) => {
    return scrollTo ? (
        <AnchorLink
            onClick={() => {
                logEvent("clicked_cta", userId, {
                    ctaId,
                });
            }}
            className={`btn ${
                white ? (primary ? "btn-white-lg" : "btn-white") : "btn-dark"
            }`}
            href={scrollTo}
            offset={60}
        >
            {title}
        </AnchorLink>
    ) : (
        <a
            href={`https://app.cincin.co.il`}
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => {
                logEvent("clicked_cta", userId, {
                    ctaId,
                });
            }}
            className={`btn ${
                white ? (primary ? "btn-white-lg" : "btn-white") : "btn-dark"
            }`}
        >
            {title}
        </a>
    );
};

export const BgPattern = ({ id, right = false, className = "" }) => (
    // <svg
    //     className={`absolute ${
    //         right ? "left-full -translate-x-1/4" : "right-full translate-x-1/4"
    //     } translate-y-[0%] lg:translate-y-0 transform lg:translate-x-[100%] opacity-70`}
    <svg
        className={`${className} opacity-70`}
        width={404}
        height={584}
        fill="none"
        viewBox="0 0 404 784"
        id={id}
    >
        <defs>
            <pattern
                id="4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
            >
                <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-rose-100"
                    fill="currentColor"
                />
            </pattern>
        </defs>
        <rect
            width={404}
            height={784}
            fill="url(#4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa)"
        />
    </svg>
);
