import { CheckIcon } from "@heroicons/react/24/solid";
import React from "react";
import { logEvent } from "../App";

export default function Plans({ userId }) {
    const plans = [
        {
            title: "הבסיסית",
            // title: "נחמד וטוב",
            price: 0.6,
            // price: 119,
            // price: 199,
            // discountPrice: 119,
            description: "פשוט אישורי הגעה נוחים",
            features: [70, 0.6, "V", "X", "X", "X", "X", "V", "V"],
            id: "BASIC",
        },
        {
            title: "המתקדמת",
            price: 0.7,
            // price: 169,
            // price: 239,
            // discountPrice: 169,
            description: "כל מה שצריך ליום הגדול",
            features: [80, 0.5, "V", "V", "V", "X", "V", "V", "V"],

            id: "PLUS",
        },
        {
            title: "המושלמת",
            price: 0.8,
            // price: 299,
            // discountPrice: 199,
            description: "ברמות של כלה",
            features: [250, 0.4, "V", "V", "V", "V", "V", "V", "V"],

            id: "PREMIUM",
        },
    ];
    // const plans = [
    //     {
    //         title: "נחמד וטוב",
    //         price: 119,
    //         // price: 199,
    //         // discountPrice: 119,
    //         description: "פשוט אישורי הגעה נוחים",
    //         features: [
    //             "150 רשומות",
    //             "₪0.6 לכל רשומה נוספת",
    //             "סבב אישורי הגעה אחד בוואטסאפ",
    //             "העלאת אנשי קשר דרך הוואטסאפ",
    //             <>
    //                 הוספת קישור למתנה
    //                 <strong className="-mr-0.5">ב-PayBox</strong>
    //             </>,
    //             "הוספת תמונה משלכם להודעה",
    //             "מענה נוח עם כפתורים",
    //         ],
    //         id: "BASIC",
    //     },
    //     {
    //         title: "מושלם",
    //         price: 169,
    //         // price: 239,
    //         // discountPrice: 169,
    //         description: "כל מה שצריך ליום הגדול",
    //         features: [
    //             "200 רשומות",
    //             "₪0.5 לכל רשומה נוספת",
    //             "2 סבבי אישורי הגעה בוואטסאפ",
    //             "הודעת תזכורת עם כל הפרטים לפני האירוע",
    //             "ביטול חינם עד שבועיים לפני השליחה",
    //             "העלאת אנשי קשר דרך הוואטסאפ",
    //             <>
    //                 הוספת קישור למתנה
    //                 <strong className="-mr-0.5">ב-PayBox</strong>
    //             </>,
    //             "הוספת תמונה משלכם להודעה",
    //             "מענה נוח עם כפתורים",
    //         ],
    //         id: "PLUS",
    //     },
    //     {
    //         title: "פרימיום",
    //         price: 199,
    //         // price: 299,
    //         // discountPrice: 199,
    //         description: "ברמות של כלה",
    //         features: [
    //             "250 רשומות כלולות",
    //             "₪0.4 לכל רשומה נוספת",
    //             "סבב אישורי הגעה ראשון",
    //             "סבב אישורי הגעה שני",
    //             // <>
    //             //     סבב אישורי הגעה
    //             //     <strong className="-mx-1">שני</strong>
    //             // </>,
    //             "הודעת תזכורת לפני האירוע",
    //             "הודעת תודה לאחר האירוע",
    //             "ביטול חינם עד שבוע לפני השליחה",
    //             <>
    //                 הוספת קישור למתנה
    //                 <strong className="-mr-0.5">ב-PayBox</strong>
    //             </>,
    //             "העלאת אנשי קשר דרך הוואטסאפ",
    //             // "הוספת תמונה משלכם להודעה",
    //             // "מענה נוח עם כפתורים",
    //         ],
    //         id: "PREMIUM",
    //     },
    // ];
    return (
        <div className="py-10 lg:px-8" id="plans">
            <h1 className="text-3xl text-center font-semibold">החבילות שלנו</h1>
            <div className="no-scrollbar flex flex-row w-screen lg:w-full overflow-y-auto gap-6 px-5 lg:gap-14 mt-10 items-center justify-start lg:justify-center">
                {/* <div className="flex flex-col lg:flex-row gap-14 mt-10 items-center justify-center"> */}
                {plans.map((plan, index) => (
                    <PlanCard
                        key={plan.title}
                        {...plan}
                        index={index}
                        userId={userId}
                    />
                ))}
            </div>
        </div>
    );
}

const PlanCard = ({
    title,
    price,
    description,
    features,
    index,
    userId,
    id,
}) => {
    /**
     * cost for 400 invities, full service - 85-90 ILS, 0.225 ILS per invitee
     * cost for 500 invities, full service - 90-100 ILS , 0.2 ILS per invitee
     *
     * old price for 400 invities - 259 ILS, 0.65 ILS per invitee
     * old price for 500 invities - 299 ILS, 0.6 ILS per invitee
     *
     */
    const featuresConfig = [
        // { title: "מס' רשומות כלולות", value: features[0] },
        // { title: "מחיר לרשומה נוספת", value: features[1] + " ₪" },
        { title: "סבב אישורים אחד בוואטסאפ", value: features[2] },
        { title: "סבב אישורים שני בוואטסאפ", value: features[3] },
        { title: "הודעת תזכורת לפני האירוע", value: features[4] },
        { title: "הודעת תודה לאחר האירוע", value: features[5] },
        { title: "ביטול חינם שבוע לפני השליחה", value: features[6] },
        { title: "הוספת קישור למתנה ב-PayBox", value: features[7] },
        { title: "העלאת אנשי קשר דרך הוואטסאפ", value: features[8] },
    ];
    return (
        <div
            className={`flex-none flex-col gap-4 h-full min-w-[240px] w-[90%] xs:w-[92%] md:max-w-[350px] items-start justify-between pt-5 pb-7 px-5 text-center bg-white border-2 ${
                index === 0
                    ? "border-rose-200/90"
                    : index === 1
                    ? "border-rose-300/90"
                    : "border-rose-400/90"
            } shadow-sm rounded-xl`}
        >
            {/* <div className="flex flex-col gap-4 flex-1 h-[83vh] sm:h-[60vh] w-5/6 items-center justify-between p-6 text-center bg-white border-2 border-rose-300 shadow-sm rounded-2xl"> */}
            <div className="flex justify-between text-start w-full">
                <div className="flex flex-col gap-0">
                    <h5 className="text-2xl font-semibold">{title}</h5>
                    {/* <p className="text-base text-slate-600 ">{description}</p> */}
                </div>
                <div className="flex flex-col text-center">
                    <p className="text-2xl font-semibold font-manrope">
                        {/* <span className="font-heebo text-xs font-normal">
                            אג'
                        </span> */}
                        ₪{price}
                    </p>
                    <span className="text-sm text-center">לרשומה</span>

                    {/* <p className="text-xl font-semibold font-manrope">
                        ₪{discountPrice}
                    </p>
                    <p className="text-lg text-slate-300 font-semibold font-manrope line-through ">
                        ₪{price}
                    </p> */}
                </div>
            </div>
            <ul className="flex flex-col h-[425px] xs:h-[365px] divide-y mt-10 sm:mt-8 gap-1 text-base text-start">
                {/* <ul className="flex flex-col h-80 divide-x-2 xs:h-72 mt-6 sm:mt-8 gap-1 text-base text-start list-disc list-inside"> */}
                {featuresConfig.map((feature) => (
                    <li
                        key={feature.title + title}
                        className="flex xs:gap-2 items-center justify-between w-full py-1 xs:py-2"
                    >
                        {/* <span className="w-[5px] h-[5px] rounded-full bg-slate-900 flex-shrink-0" /> */}

                        {feature.title}
                        <div className="w-12 flex justify-center items-center text-center">
                            {feature.value === "V" ? (
                                <CheckIcon className="w-4 h-4 text-rose-500" />
                            ) : feature.value === "X" ? (
                                // <span className="text-slate-300/80 text-2xl font-thin w-5 h-5">
                                //     -
                                // </span>
                                <hr className="bg-slate-300/80 w-2.5 h-[1px]" />
                            ) : (
                                feature.value
                            )}
                        </div>
                    </li>
                ))}
                <span className="py-1 xs:py-2">מחיר מינימום של 109 ₪</span>
            </ul>

            <div className="flex w-full justify-center items-center mt-2">
                <a
                    href={`https://app.cincin.co.il/?plan=${id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={`${
                        index === 0
                            ? "bg-rose-200/90"
                            : index === 1
                            ? "bg-rose-300/90"
                            : "bg-rose-400/90"
                    } text-white rounded-full px-2.5 xs:px-4 py-2 w-64 text-lg font-semibold`}
                    // } text-white rounded-full px-4 py-2 w-56 text-xl font-semibold`}
                    onClick={() => {
                        logEvent("clicked_plan", userId, {
                            plan: title,
                            price,
                            index,
                        });
                    }}
                >
                    מדהים! אני רוצה לנסות בחינם
                </a>
            </div>
        </div>
    );
};
