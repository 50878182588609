import formIllustration from "../assets/illustrations/completed.svg";
import notebookIllustration from "../assets/illustrations/notebook.svg";
import timeIllustration from "../assets/illustrations/time.svg";

export default function SystemUsage() {
    const usages = [
        {
            title: "יוצרים הודעת אישור הגעה עם הפרטים שתרצו. אפשר אפילו להוסיף תמונה שלכם או של ההזמנה שלכם.",
            img: formIllustration,
        },
        {
            title: "מתזמנים את אישור ההגעה לפי בחירתכם. אל תדאגו, אנחנו נמליץ לכם על הזמן המתאים ביותר",
            img: timeIllustration,
        },
        {
            title: "מנהלים את המוזמנים שלכם דרך המערכת האינטואיטיבית שלנו. אפילו סבתות מבינות איך להשתמש בה בשניות",
            img: notebookIllustration,
        },
    ];

    return (
        <div
            id="system-usage"
            className="py-12"
            // className="pb-10 lg:px-96 xl:px-[30rem] lg:mt-10"
        >
            <h3 className="text-center text-3xl font-semibold pb-0.5 w-[88%] lg:w-[25%] mx-auto">
                התהליך שלכם ב-3 שלבים
                <div
                    id="system-usage-title-line"
                    className="border-b-[4px] h-1 border-rose-300 w-full"
                />
            </h3>
            <div className="flex flex-col gap-0 lg:flex-row mt-10 lg:justify-center lg:mx-auto">
                {usages.map((usage, index) => (
                    <SystemCard
                        key={usage.title + index}
                        index={index}
                        {...usage}
                    />
                ))}
            </div>
        </div>
    );
}

const SystemCard = ({ title, img, index }) => {
    return (
        <div className="flex flex-col gap-5 items-center justify-center w-full lg:max-w-[400px] px-10 h-[425px]">
            <div className="flex items-end relative w-2/3 h-[200px]">
                <img
                    src={img}
                    alt="illustration"
                    className="w-full h-full"
                    id={`illustration-image-${index}`}
                />
            </div>
            <div
                id={`illustration-title-${index}`}
                className="text-xl font-medium text-start"
            >
                {title}
            </div>
        </div>
    );
};
