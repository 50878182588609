import {
    ChatBubbleLeftEllipsisIcon,
    GiftIcon,
    UserGroupIcon,
} from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import React from "react";
import appDemo from "../assets/iphone-app-demo-portrait.webp";

gsap.registerPlugin(ScrollTrigger);

export default function MainFeature() {
    const features = [
        {
            title: "הודעות שמגיעות לכל האורחים",
            description: "הודעה אישית בוואטסאפ עם תמונה וכפתורי תשובה",
        },
        {
            title: "מתנה באשראי? יש PayBox!",
            description:
                "חסכו לאורחים שלכם עמלות מעצבנות ופשוט צרפו קישור למתנה ב-PayBox",
        },
        {
            title: "יצירת רשימת מוזמנים בשנייה",
            description:
                "פיתחנו בוט מיוחד שתוכלו לשלוח לו את כל אנשי הקשר בהודעה אחת",
        },
    ];
    return (
        <div className="flex flex-col gap-10 pb-10 px-5 text-center">
            <div className="flex flex-col lg:flex-row gap-10 pb-10 px-2 text-center lg:mx-auto">
                <FeatureCard
                    {...features[0]}
                    Icon={ChatBubbleLeftEllipsisIcon}
                />
                <FeatureCard {...features[1]} Icon={GiftIcon} />
                <FeatureCard {...features[2]} Icon={UserGroupIcon} />
            </div>
            <div className="flex flex-col lg:flex-row gap-10 lg:gap-32 justify-center items-center lg:items-center lg:py-10">
                <h4 className="hidden lg:inline text-2xl lg:text-5xl font-semibold lg:font-medium lg:w-[400px] text-center lg:text-start">
                    מערכת שתתאהבו בה ממבט ראשון{" "}
                    <HeartIcon className="hidden w-6 h-6 lg:h-10 lg:w-10 lg:inline-block text-rose-500" />
                </h4>
                <img src={appDemo} alt="app demo" className="w-5/6 lg:w-1/4" />
            </div>
        </div>
    );
}

const FeatureCard = ({ title, description, Icon }) => {
    // const containerRef = useRef(null);
    // const { isMobileDevice } = useScreenBreakpoint();

    // useLayoutEffect(() => {
    //     let ctx = gsap.context(() => {
    //         gsap.timeline({
    //             scrollTrigger: {
    //                 trigger: containerRef.current,
    //                 start: "top 50%",
    //                 end: "bottom 54%",
    //                 // markers: true,
    //                 toggleActions: "play reverse restart reverse",
    //             },
    //         }).to(containerRef.current, {
    //             backgroundColor: "#F2003C",
    //             scale: 1.05,
    //             color: "white",
    //             duration: 0.1,
    //         });
    //     });
    //     return () => ctx.revert();
    // }, [containerRef, isMobileDevice]);

    return (
        <div
            // ref={containerRef}
            className="hover:bg-primary hover:scale-105 transition-all hover:text-white h-[250px] xs:h-[214px] lg:max-w-[400px] flex-1 border-[1.5px] rounded-xl bg-white border-primary px-5 py-6 flex flex-col gap-2.5 items-center"
        >
            <h5 className="font-semibold text-2xl">{title}</h5>
            <p className="text-center h-[70px] xs:h-[50px]">{description}</p>
            {Icon && <Icon className="mt-2.5 h-10 w-10 mx-auto" />}
        </div>
    );
};
