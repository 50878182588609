import { SwipeableDrawer } from "@mui/material";
import React, { useMemo, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { logEvent } from "../App";
import { HOW_IT_WORKS_SECTION_ID } from "../constants";
import useScrollPosition from "../hooks/useScrollPosition";
import Logo from "./Logo";

export default function Navbar({ userId }) {
    const [openDrawer, setOpenDrawer] = useState(false);
    const scrollPosition = useScrollPosition();

    const routes = [
        {
            title: "מחירון",
            href: "#plans",
            offset: 50,
        },
        {
            title: "איך זה עובד",
            href: `#${HOW_IT_WORKS_SECTION_ID}`,
            offset: 70,
        },
        {
            title: "התהליך שלכם",
            href: "#system-usage",
            offset: 80,
        },
    ];

    const scrolled = useMemo(() => scrollPosition > 20, [scrollPosition]);
    return (
        <nav
            className={`fixed z-40 w-screen h-16 text-black ${
                scrolled ? "bg-white shadow-sm" : "bg-transparent"
            } flex items-center justify-between px-4 lg:px-10 transition-all duration-100`}
        >
            <div className="flex lg:gap-6 items-center">
                <Logo dark />
                <div className="hidden lg:flex gap-10 items-center justify-start h-full">
                    {routes.map((route) => (
                        <AnchorLink
                            key={route.title}
                            onClick={() => setOpenDrawer(false)}
                            href={route.href}
                            className="text-white text-xl font-medium hover:underline"
                            offset={route.offset}
                        >
                            {route.title}
                        </AnchorLink>
                    ))}
                </div>
            </div>

            <div className="flex gap-4 items-center">
                <AnchorLink
                    href="#plans"
                    offset={60}
                    // href="https://app.cincin.co.il/"
                    onClick={async () =>
                        await logEvent("clicked_cta", userId, {
                            ctaId: "navbar",
                        })
                    }
                    className={`text-primary border-primary  border rounded-full transition-all px-3 py-1 lg:text-xl lg:px-6 lg:font-medium lg:border-2`}
                    // className={`
                    //     ${
                    //     scrolled
                    //         ? "text-primary-dark"
                    //         : "bg-primary-dark text-white"
                    // }
                    //          text-primary-dark border-primary-dark  border rounded-full transition-all px-3 py-1 lg:text-xl lg:px-6 lg:font-medium lg:border-2`}
                >
                    מחירון
                </AnchorLink>
                <button
                    className="lg:hidden"
                    onClick={() => setOpenDrawer(true)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-8 h-8 stroke-[1.5]"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 9h16.5m-16.5 6.75h16.5"
                        />
                    </svg>
                </button>
                <div className="hidden lg:block">
                    <a
                        href={`https://app.cincin.co.il/login`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="btn btn-black !w-40"
                        disabled
                    >
                        התחברות
                    </a>
                </div>
            </div>
            <SwipeableDrawer
                className="lg:hidden"
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
            >
                <div className="font-assistant flex flex-col justify-between w-[80vw] h-screen pb-20 px-6 pt-6">
                    <div className="flex justify-between items-center">
                        <button onClick={() => setOpenDrawer(false)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-8 h-8 stroke-[1.5]"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <Logo />
                    </div>
                    <div className=" flex flex-col gap-0 pt-10 items-end w-full h-full divide-y divide-slate-200/50">
                        {routes.map((route) => (
                            <AnchorLink
                                key={route.title}
                                onClick={() => setOpenDrawer(false)}
                                href={route.href}
                                className="text-black text-end text-2xl font-medium w-full py-3"
                                offset={route.offset}
                            >
                                {route.title}
                            </AnchorLink>
                        ))}
                    </div>
                    <a
                        href={`https://app.cincin.co.il/login`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="btn btn-black w-56"
                        disabled
                    >
                        התחברות
                    </a>
                </div>
            </SwipeableDrawer>
        </nav>
    );
}
